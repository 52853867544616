import React, { Component } from "react";
import { withRouter } from "react-router";
import MediaQuery from "react-responsive";
import Modal from "react-modal";
import i18n from "../../../i18n";
import {
  cloneJob,
  toggleAttendance,
  updateJob,
  assignSingle,
  deleteMultipleJobs,
  getCurrentJob,
} from "../../../services/api/jobs";
import StatusSelect from "./editor/status_select";
import {
  updateProject,
  getAllProjectList,
} from "../../../services/api/projects";
import { JOB_STATUS } from "../../../services/job_status";
import { getUser } from "../../../services/api/user";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { getJobTypeList } from "../../../services/api/job_type";
import { getProjectsList } from "../../../services/api/projects";
import {
  getFileInfo,
  getFileTarget,
  s3Delete,
  s3Upload,
} from "../../../services/api/file_upload";
import { QuestionType, getFileIcon } from "../../../services/question";
import Confirm from "../../../components/confirm/confirm";
import UserEditor from "../../users/editor";
import UserSelectModal from "../../../layout/partials/modals/user_select_modal";
import CircleUserList from "../../../components/circle_user_list/circle_user_list";
import FullScreenModal from "../../../layout/partials/modals/full_screen_modal";
import QuestionRenderer from "../../../components/question/question_renderer";
import { ROLE } from "../../../services/roles";
import { connectWithStore } from "../../../services/redux";
import "../editor.scss";
import { isMobileDevice } from "../../../services/mobile";
import AutoSelect from "../../../components/autoselect/autoselect";
import DateService from "../../../services/date";
import ToggleService from "../../../services/toggle";
import MapWithButtons from "../../../components/google_map/map_with_buttons";
import ConfirmationButton from "../../../components/confirmation_button/confirmation_button";
import FileManager from "../../../components/file_manager/file_manager";
import {
  CurrentProject,
  GridTable,
  StepQuestions,
  UserAutocomplete,
  BlueprintEditor,
} from "../../../redux/actions";
import SkillAutoComplete from "../../../components/autoselect/skill_autoselect";
import { assignSkillsToJob } from "../../../services/api/skills";
import Grid from "@material-ui/core/Grid";
import withModal from "../../../hoc/withModal";
import SkillChip from "../../../components/skills/skill_chip";
import SKILL_COLORS from "../../../services/skill_colors";
import { Tabs, Tab, AppBar, Button } from "@material-ui/core";
import TabPanel from "../../../components/tabs/tab_panel";
import ProjectFieldRenderer from "../../projects/project_field_renderer";
import { withStyles } from "@material-ui/core/styles";
//import { getAnswerJsx } from "../../../services/answers";
import ReactTooltip from "react-tooltip";
import QuestionSummary from "../../../components/answer/question_summary";
import ExportJobButton from "../../../components/jobs/export_job_button";
import ToastMessage from "../../../components/toast_message/toast_message";
import MobileJobRow from "./mobile_job_row";
import { APP_PROJECTS, APP_STEPS } from "../../../services/app";
import ShowFilesButton from "./show_files_button";
import PreviousJob from "./editor/previous_job";
import NextJob from "./editor/next_job";
import JobHeader from "./editor/job_header";
import JobActivities from "./editor/job_activities";

const avatar = require("../../../assets/img/project-placeholder.png");

const customStyles = {
  content: {
    padding: 0,
    height: isMobileDevice() ? "100%" : 500,
    width: isMobileDevice() ? "100%" : 500,
  },
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: "white",
    color: "#B0BAC9",
    boxShadow: "none",
    marginTop: "20px",
  },
  selectedTab: {
    color: "#2E384D",
    borderBottom: "3px solid #2E384D",
  },
  customTabIndicator: {
    backgroundColor: "white",
  },
  settingsTab: {
    outline: "none !important",
    minHeight: "45px",
    height: "45px",
    paddingTop: "10px",
    minWidth: "60px",
    width: "30%",
  },
  circleJobButton: {
    width: "24px",
    height: "24px",
  },
};

const EDITOR_MODE = {
  JOB: 0,
  USER: 1,
};

export const renderIcon = (files, fileInfo) => {
  const filteredFiles = files.filter((file) => file !== null);
  if (
    !filteredFiles[0] ||
    filteredFiles[0].length === 0 ||
    (fileInfo && Object.keys(fileInfo).length === 0)
  ) {
    return "";
  }

  if (!fileInfo || !fileInfo[filteredFiles[0]]) {
    return "";
  }
  const extension = fileInfo[filteredFiles[0]].extension;
  const icon = getFileIcon(extension);
  const file = filteredFiles[0];
  if (icon.includes("-image") && file) {
    return (
      <div>
        <img src={getFileTarget(file, "100x100")} alt={file.Name} />{" "}
        {filteredFiles.length > 1 && (
          <span className="image-files-length">
            ({filteredFiles.length - 1})
          </span>
        )}
      </div>
    );
  }
  return (
    <div>
      <i className={icon} />
      {filteredFiles.length > 1 && (
        <span className="image-files-length">({filteredFiles.length - 1})</span>
      )}
    </div>
  );
};

class JobEditor extends Component {
  constructor(props) {
    super(props);
    const secondTab =
      props.jobs &&
      props.jobs[0] &&
      props.jobs[0].jobType.settings.previewSettings.filter(
        (v) => v.value.toString() !== "-1"
      ).length === 0
        ? true
        : this.props.initial;

    this.state = {
      jobs: props.jobs || [],
      currentJob: {},
      deleteConfirm: false,
      deleteSkillConfirm: false,
      mode: EDITOR_MODE.JOB,
      user: null,
      changeStatus: false,
      changeJobType: false,
      changeProject: false,
      assignUsersModal: false,
      assignedUsers: [],
      showAnswerModal: false,
      selectedQuestions: [],
      copyLink:
        props.jobs && props.jobs[0]
          ? window.location.origin.concat("/jobs/").concat(props.jobs[0].ID)
          : null,
      modalProjectsOpen: false,
      showLocationModal: this.props.openLocation,
      removeAttendanceConfirm: false,
      removeAttendanceUserId: null,
      statusSelectMenuOpened: false,
      showFilesModal: false,
      filesModalFiles: [],
      togglePreviousJobActive: false,
      currentTab: secondTab ? 2 : 0,
      shouldLoadSkillsTab: false,
      addModalVisible: false,
      selectedSkills: [],
      initialSkills: [],
      selectedToRemove: [],
      jobLoading: false,
      fileInfo: {},
      showCopyAlert: false,
      selectionMode: false,
    };
    this.props.dispatch(
      CurrentProject.setProject(
        props.jobs && props.jobs[0] ? props.jobs[0].project : null
      )
    );
    this.jobTypeList = [];
    this.projectList = [];
    this.projectListOptions = [];
    this.jobTypeSelectRef = null;
    this.projectSelectRef = null;
    this.statusSelectRef = React.createRef();
    this.multiStatusSelectRef = React.createRef();
    this.textAreaRef = null;
    this.refUsers = null;

    this.allowedFiles = "image/jpeg, image/png";
  }

  async checkSkillsToggle() {
    const response = await ToggleService._isToggleActive(
      "skills",
      this.props.settings.toggles
    );
    this.setState({ shouldLoadSkillsTab: response });
  }

  openModal() {
    this.setState({ addModalVisible: true });
  }

  closeModal() {
    this.setState({ addModalVisible: false });
  }

  handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showCopyAlert: false });
  };

  handleAlertOpen = () => {
    this.setState({ showCopyAlert: true });
  };

  apiLoaded(map, maps) {
    this.setState({
      mapsApiLoaded: true,
      mapInstance: map,
      mapsapi: maps,
    });
  }

  renderFileModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.showFilesModal}
        onRequestClose={() => this.setState({ showFilesModal: false })}
        style={{
          content: {
            padding: 0,
            width: 489,
            height: 500,
          },
        }}
      >
        <div className="Modal">
          <div className="Modal-header">
            <i
              className="mdi mdi-chevron-left chevron-left"
              onClick={() => this.setState({ showFilesModal: false })}
            />
            <h5>{i18n.t("default:_FILE_LIST")}</h5>
          </div>

          <div className="Modal-content NoPadding">
            <FileManager files={this.state.filesModalFiles} viewOnly={true} />
          </div>
        </div>
      </Modal>
    );
  }

  async updateFileInfo(currentAnswers) {
    const fileInfo = {};
    for (const answer of currentAnswers) {
      if (answer.files && answer.files.length > 0) {
        for (const file of answer.files) {
          fileInfo[file] = await getFileInfo(file);
        }
      }
    }
    this.setState({
      fileInfo,
    });
  }

  async componentDidMount() {
    document.body.style.overflow = isMobileDevice() ? "hidden" : "auto";
    const { selectedRows, selectionMode } = this.props.gridTable;
    const jobId = selectedRows[0]
      ? selectedRows[0]._id
      : this.props.jobs[0]._id;
    const currentJob = await getCurrentJob({ id: jobId }, "v2");
    this.setState({ currentJob });

    this.checkSkillsToggle();
    const projectResponse = await getProjectsList();
    const jobTypeResponse = await getJobTypeList();
    this.jobTypeList = jobTypeResponse;
    this.projectList = projectResponse.docs;
    if (this.projectList) {
      this.projectList.forEach((project) => {
        this.projectListOptions.push({
          selected: project._id === currentJob.project._id,
          key: project._id,
          value: JSON.stringify(project),
          label: project.name,
        });
      });
    }

    const currentSkills = currentJob.requiredSkills
      ? currentJob.requiredSkills
      : [];

    this.setState({
      togglePreviousJobActive:
        (await ToggleService.isToggleActive("previous_job")) === true,
      initialSkills: currentSkills,
      selectedSkills: currentSkills,
      selectionMode,
    });

    this.forceUpdate();

    if (this.state.copyLink !== null) {
      let copyUrl = `${currentJob.ID} - ${currentJob.project.name} - ${
        currentJob.jobType.title
      } - ${DateService.getFormattedDate(
        currentJob.startDate,
        null,
        this.props.settings.timezone
      )}\n${this.state.copyLink}`;

      this.setState({
        copyLink: copyUrl,
      });
    }

    document.addEventListener("click", (ev) => {
      if (!ev.target.classList.contains("mdi-history")) {
        const targetItems = document.querySelectorAll("[data-role=popup]");

        if (targetItems && targetItems.length > 0) {
          for (const item of targetItems) {
            item.classList.remove("show");
          }
        }
      }
    });

    if (currentJob && currentJob.answers) {
      this.updateFileInfo(currentJob.answers);
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  async onDelete() {
    const { jobs } = this.state;
    this.props.dispatch(showLoading());
    try {
      const ids = jobs.map((job) => job._id);
      await deleteMultipleJobs({
        ids,
      });
    } catch (e) {}
    this.props.dispatch(hideLoading());
    this.props.refresh();
    this.closeConfirm();
    if (this.props.onDelete) {
      this.props.onDelete();
    }
  }

  validateNotEmpty(val) {
    return val.length > 0;
  }

  async cloneJob() {
    const { currentJob } = this.state;
    const id = currentJob._id;
    this.props.dispatch(showLoading());
    try {
      await cloneJob({
        id: id,
      });
      alert(i18n.t("default:_JOB_CLONED"));
    } catch (e) {
      alert(i18n.t("default:_JOB_CLONE_FAILED"));
    }
    this.props.dispatch(hideLoading());
    this.props.refresh();
  }

  confirmDelete() {
    this.setState({
      deleteConfirm: true,
    });
  }

  closeConfirm() {
    this.setState({
      deleteConfirm: false,
    });
  }

  onDeselect() {
    if (this.props.onDeselect) {
      this.props.onDeselect();
    }
  }

  onPrepareReport() {
    alert("Under development");
  }

  async openUser(id) {
    const user = await getUser({
      id: id,
    });
    this.setState({
      user: user,
      mode: EDITOR_MODE.USER,
    });
  }

  closeUser() {
    this.setState({
      user: null,
      mode: EDITOR_MODE.JOB,
    });
  }

  changeJobProp(propName, newValue) {
    const { currentJob } = this.state;
    currentJob[propName] = newValue[propName];
    this.setState(
      {
        currentJob: currentJob,
        changeStatus: false,
      },
      () => this.onJobDataChanged()
    );
  }

  async changeJobStatusMulti(value) {
    const { jobs } = this.state;
    for (let job of jobs) {
      const requestJob = Object.assign({}, job);
      requestJob.status = value;
      requestJob.id = job._id;
      requestJob.users = requestJob.users.map((user) => user._id);
      requestJob.project = job.project._id;
      requestJob.jobType = job.jobType._id;
      delete requestJob.questions;
      delete requestJob.answers;
      delete requestJob.created_at;
      delete requestJob.updated_at;
      job.status = value;
      await updateJob(requestJob);
    }
    if (this.props.onMultipleUpdate) {
      this.props.onMultipleUpdate(jobs);
    }
    this.setState({ jobs }, () => this.forceUpdate());
  }

  async onJobDataChanged() {
    const { currentJob } = this.state;
    const requestJob = Object.assign({}, currentJob);
    requestJob.id = currentJob._id;
    requestJob.users = requestJob.users.map((user) => user._id);
    requestJob.project = currentJob.project._id;
    requestJob.jobType = currentJob.jobType._id;
    delete requestJob.questions;
    delete requestJob.answers;
    delete requestJob.created_at;
    delete requestJob.updated_at;

    await updateJob(requestJob);
    if (this.props.onUpdate) {
      this.props.onUpdate(currentJob);
    }
  }

  toggleChangeStatus() {
    if (
      !this.props.settings.security.allowChangeStatus &&
      this.props.user.profile.role === "inspector"
    ) {
      return false;
    }

    const { changeStatus } = this.state;
    this.setState({
      changeStatus: changeStatus ? false : true,
    });
  }

  toggleChangeProject() {
    const { changeProject } = this.state;
    this.setState({
      changeProject: changeProject ? false : true,
    });
  }

  toggleChangeJobType() {
    const { changeJobType } = this.state;
    this.setState({
      changeJobType: changeJobType ? false : true,
    });
  }

  toggleAssignUsers() {
    const { assignUsersModal } = this.state;
    this.setState({
      assignUsersModal: assignUsersModal ? false : true,
    });
  }

  handlePanelClick(event) {
    if (
      this.jobTypeSelectRef &&
      !this.jobTypeSelectRef.contains(event.target) &&
      this.state.changeJobType
    ) {
      this.setState({ changeJobType: false });
    }
    if (
      this.projectSelectRef &&
      !this.projectSelectRef.contains(event.target) &&
      this.state.changeProject
    ) {
      this.setState({ changeProject: false });
    }

    if (
      this.statusSelectRef.current &&
      event.target &&
      event.target.className &&
      ((!event.target.className.baseVal &&
        event.target.className.indexOf(
          this.statusSelectRef.current.props.className
        ) === -1) ||
        (event.target.className.baseVal &&
          event.target.className.baseVal.indexOf(
            this.statusSelectRef.current.props.className
          ) === -1)) &&
      this.state.changeStatus
    ) {
      this.setState({ changeStatus: false });
    }

    if (
      this.multiStatusSelectRef.current &&
      this.state.jobs.length > 1 &&
      this.state.changeStatus
    ) {
      this.setState({ changeStatus: false });
    }
  }

  assignUsers(users) {
    this.props.dispatch(UserAutocomplete.setAssignedUsers(users));
    this.setState({
      assignedUsers: users,
    });
  }

  async componentDidUpdate(prevProps) {
    const { selectedRows, selectionMode } = this.props.gridTable;
    const { currentJob } = this.state;
    if (this.props.openQuestion !== null) {
      this.editQuestion(this.props.openQuestion, true);
      if (this.props.resetOpenQuestion) {
        this.props.resetOpenQuestion();
      } else {
        this.props.dispatch(GridTable.setRightPanelWithQuestion(null));
      }
      this.props.dispatch(
        CurrentProject.setProject(this.props.jobs[0].project)
      );
    }
    if (
      currentJob &&
      selectedRows.length === 1 &&
      currentJob.ID !== selectedRows[0].ID
    ) {
      this.setState({
        currentJob: selectedRows[0],
        selectionMode,
      });
    }
    try {
      if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
        const { isBpEditorOpen } = this.props.blueprintEditor;
        if (isBpEditorOpen) return;
        const jobId = selectedRows[0]
          ? selectedRows[0]._id
          : this.props.jobs[0]._id;
        const currentJob = await getCurrentJob({ id: jobId }, "v2");
        this.setState({
          jobs: this.props.jobs,
          currentJob,
          showLocationModal: this.props.openLocation,
          selectionMode,
        });
      }
    } catch (e) {
      //   console.log(e);
    }
  }

  async updateAssignees() {
    const { jobs } = this.state;
    this.setState({
      assignUsersModal: false,
    });
    setTimeout(async () => {
      const { assignedUsers } = this.props.userAutocomplete;
      const requestUsers = assignedUsers.map((user) => user.value);
      for (let job of jobs) {
        /* await assignMultiple({
          users: requestUsers,
          jobs: requestJobs,
        }); */
        assignedUsers.forEach((user) => {
          job.users.push(user.value);
        });
        await assignSingle({
          id: job._id,
          users: requestUsers,
        });
        if (this.props.onUpdate) {
          this.props.onUpdate(job, true);
        }
      }
      this.setState({
        assignedUsers: [],
      });
    }, 1000);
  }

  editQuestion(index, forcePreview = false, event = null) {
    if (
      event &&
      (event.target.tagName === "IMG" ||
        event.target.className === "image-files-length" ||
        event.target.classList.contains("mdi-history") ||
        event.target.classList.contains("mdi-file-pdf") ||
        event.target.classList.contains("UserAnswers") ||
        event.target.classList.contains("UserAnswers-item"))
    ) {
      return false;
    }
    const { currentJob, currentTab } = this.state;
    const { blueprintEditor } = this.props;
    const { rightPanelJob } = blueprintEditor;

    const questions =
      currentTab === 2 && !forcePreview
        ? this.getAllQuestions()
        : this.getPreviewQuestions(false);

    const question =
      questions &&
      Object.assign(questions[index], {
        answer: null,
      });

    if (!this.canInspectorAnswer(currentJob, question)) {
      return false;
    }

    //check if question was clicked from bp editor job side panel
    if (question.type === QuestionType.BLUEPRINT && rightPanelJob) {
      return false;
    }

    //allow open bp editor from table slide
    if (question.type === QuestionType.BLUEPRINT && forcePreview) {
      this.props.dispatch(BlueprintEditor.setIsBpEditorOpen(true));
    }

    const filter = currentJob.answers.filter((a) => {
      return (
        a.question && a.question.ID.split("_")[0] === question.ID.toString()
      );
    });
    const answer =
      filter.length > 0 ? filter[filter.length - 1] : { value: "" };
    if (answer) {
      question.answer = answer;
    }
    this.setState({
      showAnswerModal: true,
      selectedQuestions: [question],
    });
  }

  getPreviewQuestions(onlyInfo = true) {
    const { currentJob } = this.state;

    const res = currentJob.jobType
      ? currentJob.jobType.settings.previewSettings
          .filter(
            (p) =>
              currentJob.questions
                .map((q) => q.ID.toString())
                .indexOf(p.value) > -1
          )
          .sort(
            (a, b) =>
              currentJob.questions
                .filter(
                  (q) =>
                    currentJob.jobType.settings.previewSettings
                      .map((p) => p.value)
                      .indexOf(q.ID.toString()) > -1
                )
                .filter((q) => q.ID.toString() === a.value)
                .filter((q) => q !== undefined)[0].sequence -
              currentJob.questions
                .filter(
                  (q) =>
                    currentJob.jobType.settings.previewSettings
                      .map((p) => p.value)
                      .indexOf(q.ID.toString()) > -1
                )
                .filter((q) => q.ID.toString() === b.value)
                .filter((q) => q !== undefined)[0].sequence
          )
      : [];

    if (onlyInfo) {
      return res;
    }

    const questions = [];
    res.forEach((item) => {
      const question = currentJob.questions.filter(
        (q) => q.ID === parseInt(item.value)
      );

      if (question && question[0]) {
        questions.push(question[0]);
      }
    });

    return questions;
  }

  getAllQuestions() {
    const { currentJob } = this.state;

    return currentJob.questions
      ? currentJob.questions.sort((a, b) => (a.sequence < b.sequence ? -1 : 1))
      : [];
  }

  editAll() {
    const { currentJob } = this.state;
    const selectedQuestions = [];

    if (!this.canInspectorAnswer(currentJob)) {
      return false;
    }

    const questions = this.getAllQuestions();

    questions.forEach((question) => {
      const newQuestions = Object.assign(question, {
        answer: null,
      });
      const filter = currentJob.answers.filter((a) => {
        return a.question.ID.split("_")[0] === question.ID.toString();
      });
      const answer =
        filter.length > 0 ? filter[filter.length - 1] : { value: "" };
      if (answer) {
        newQuestions.answer = answer;
      }
      selectedQuestions.push(newQuestions);
    });

    //set steps modal open if steps are on
    if (this.props.apps.enabled.includes(APP_STEPS)) {
      this.props.dispatch(StepQuestions.setStepsModalOpen(true));
    }

    //set bpEditorOpen to true if there are bp questions on Answer all
    const bpQuestions = selectedQuestions.filter(
      (q) => q.type === QuestionType.BLUEPRINT
    );
    if (bpQuestions && bpQuestions.length > 0) {
      this.props.dispatch(BlueprintEditor.setIsBpEditorOpen(true));
    }

    this.setState({
      showAnswerModal: true,
      selectedQuestions: selectedQuestions,
    });
  }

  checkForNewIssues(answers, jobAnswers) {
    //check if there are newly answered questions with Issue
    const answersWithIssue = answers.filter((answer) => answer.isIssue);
    if (answersWithIssue.length > 0) {
      answersWithIssue.forEach((answer) => {
        //compare new answer with previous answer to see if is new issue & update job status if so
        const questionAnswers = jobAnswers.filter(
          (jobAnswer) => jobAnswer.ID === answer.ID
        );
        const lastTwoQuestionAnswers = questionAnswers.slice(-2);
        if (lastTwoQuestionAnswers.length > 1) {
          const previousAnswer = lastTwoQuestionAnswers[0];
          const lastAnswer = lastTwoQuestionAnswers[1];
          if (lastAnswer.isIssue && !previousAnswer.isIssue) {
            this.changeJobProp("status", { status: JOB_STATUS.REVIEW });
          }
        } else {
          this.changeJobProp("status", { status: JOB_STATUS.REVIEW });
        }
      });
    }
  }

  updateAnswers(result) {
    const { currentJob } = this.state;
    for (let index in result) {
      const row = result[index];
      currentJob.answers.push(row);
    }
    this.updateFileInfo(currentJob.answers);

    this.checkForNewIssues(result, currentJob.answers);

    this.props.onUpdate(currentJob);

    return currentJob;
  }

  questionAnswered() {
    this.setState({
      showAnswerModal: false,
      selectedQuestions: [],
    });
  }

  onShare() {
    this.textAreaRef.select();
    document.execCommand("copy");
    this.handleAlertOpen();
  }

  onReplace(acceptedFiles) {
    const { currentJob } = this.state;

    if (acceptedFiles.length === 1) {
      this.setState({ loading: true }, async () => {
        const allowed = this.allowedFiles.split(", ");
        const file = acceptedFiles[0];
        if (allowed.indexOf(file.type) === -1) {
          alert(`Invalid file extension allowed only ${allowed}`);
          return;
        }

        const formData = new FormData();
        formData.append("file", file);

        if (currentJob.avatar) {
          await s3Delete({
            file: currentJob.project.avatar,
          });
        }
        const response = await s3Upload(formData);

        let newJobData = Object.assign({}, currentJob); //creating copy of object
        newJobData.project.avatar = response.assetId; //updating value
        updateProject({
          avatar: response.assetId,
          id: currentJob.project._id,
        });
        this.setState(
          {
            currentJob: newJobData,
            loading: false,
          },
          () => this.onJobDataChanged()
        );
      });
    }
  }

  renderIcon(files) {
    return renderIcon(files);
  }

  renderQuestion({ index, question, currentAnswer, project }) {
    const settings = this.props.settings;
    let lang = "";

    if (settings.settings && settings.settings.default_language) {
      lang = settings.settings.default_language;
    }

    const currentUser = this.props.user.profile;

    if (currentUser && currentUser.language) {
      lang = currentUser.language;
    }
    if (
      currentUser &&
      currentUser.role &&
      currentUser.role === ROLE.CLIENT &&
      question &&
      !question.isClientVisible
    ) {
      return null;
    }
    return (
      <QuestionSummary
        key={index}
        index={index}
        question={question}
        currentAnswer={currentAnswer}
        answers={
          currentAnswer && currentAnswer.history ? currentAnswer.history : {}
        }
        cb={this.editQuestion.bind(this)}
        onOpenFilesModal={(files) =>
          this.setState({ showFilesModal: true, filesModalFiles: files })
        }
        currentUserLang={lang}
        fileInfo={this.state.fileInfo}
        project={project}
      />
    );
  }

  refreshLocation() {
    if (window.location.href.includes("/location")) {
      window.location.href = window.location.href.replace("/location", "");
    }
  }

  renderLocationModal() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.showLocationModal}
        style={customStyles}
        onRequestClose={() => {
          this.setState({ showLocationModal: false }, () =>
            this.refreshLocation()
          );
        }}
      >
        <div className="Modal">
          <div className="Modal-header">
            <div className="modal-mobile-header">
              <div>
                <div
                  onClick={() =>
                    this.setState({ showLocationModal: false }, () =>
                      this.refreshLocation()
                    )
                  }
                  style={{ fontSize: 22 }}
                  className="close-modal-mobile"
                >
                  <i className="mdi mdi-chevron-left close" />
                </div>
              </div>
            </div>
            <h5>{i18n.t("default:_ADDRESS")}</h5>
          </div>

          <div className="Modal-content">
            <MapWithButtons
              customChangeHandler={() =>
                this.props.history.push(
                  `/project/view/${this.state.currentJob.project._id}/location`
                )
              }
              customShareLink={
                window.location.href
                  .replace("#", "")
                  .replace(`/${this.props.jobs[0].ID}`, "") +
                `/${this.props.jobs[0].ID}/location`
              }
              project={this.state.currentJob.project}
              copyLink={(link) => {
                const original = Object.assign(
                  {},
                  { link: this.state.copyLink }
                );
                this.setState(
                  {
                    copyLink: link,
                  },
                  () => {
                    this.onShare();
                    setTimeout(() => {
                      this.setState({
                        copyLink: original.link,
                      });
                    }, 2000);
                  }
                );
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }

  onProjectChange(value) {
    this.changeJobProp("project", {
      project: {
        _id: typeof value === "object" ? value[0].value : value,
        name: typeof value === "object" ? value[0].label : "",
      },
    });
  }

  renderProjects() {
    const requestURL = {
      get: async () => {
        const projects = await getAllProjectList({});
        const defaultAvatar = require("../../../assets/img/project-placeholder.png");
        const result =
          projects.docs && projects.docs.length > 0
            ? projects.docs.map(({ _id, avatar, name }) => {
                return {
                  _id,
                  name,
                  avatar: avatar ? (
                    <img src={getFileTarget(avatar, "100x100")} alt={name} />
                  ) : (
                    <img src={defaultAvatar} alt={name} />
                  ),
                };
              })
            : [];
        return result;
      },
    };
    return (
      <AutoSelect
        valueFromID={true}
        maxSelection={1}
        requestUrl={requestURL}
        onChange={(e) => this.onProjectChange(e)}
      />
    );
  }

  renderEditProjectsModal() {
    const CloseIcon = () => {
      return (
        <MediaQuery maxWidth={992}>
          <div className="modal-mobile-header">
            <div>
              <div
                onClick={() => this.setState({ modalProjectsOpen: false })}
                style={{ fontSize: 22 }}
                className="close-modal-mobile"
              >
                <i className="mdi mdi-chevron-left" />
              </div>
            </div>
          </div>
        </MediaQuery>
      );
    };
    const customStyles = {
      content: {
        padding: 0,
        height: isMobileDevice() ? "100%" : 500,
        width: isMobileDevice() ? "100%" : 500,
      },
    };
    return (
      <div>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalProjectsOpen}
          style={customStyles}
          onRequestClose={() => {
            this.setState({ modalProjectsOpen: false });
          }}
        >
          <div className="Modal-materials">
            <div className="Modal-header">
              <CloseIcon />
              <h5>{i18n.t("default:_PROJECTS")}</h5>
            </div>

            <div className="Modal-content">{this.renderProjects()}</div>

            <div className="Modal-actions">
              <button
                onClick={() => {
                  this.setState({ modalProjectsOpen: false });
                }}
                className="btn btn-primary"
              >
                {i18n.t("default:_SELECT")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  canCloneJob() {
    const { allowInspectorDuplicate } = this.props.settings.security;
    const { role } = this.props.user.profile;
    return !(allowInspectorDuplicate === false && role === ROLE.INSPECTOR);
  }

  canEraseJob() {
    const { allowErase } = this.props.settings.security;
    const { role } = this.props.user.profile;
    const condition =
      allowErase === false &&
      [ROLE.INSPECTOR, ROLE.PROJECT_MANAGER].includes(role);
    return !condition;
  }

  isUserInspector() {
    return (
      this.props.user &&
      this.props.user.profile &&
      this.props.user.profile.role === ROLE.INSPECTOR
    );
  }

  isUserClient() {
    return (
      this.props.user &&
      this.props.user.profile &&
      this.props.user.profile.role === ROLE.CLIENT
    );
  }

  canInspectorAnswer(job, question) {
    if (this.isUserClient()) {
      if (
        question &&
        question.type === QuestionType.BLUEPRINT &&
        question.settings &&
        question.settings.permissions &&
        question.settings.permissions.client &&
        question.settings.permissions.client === true
      ) {
        return true;
      }
      return false;
    }
    if (!job.status) {
      return true;
    }
    if (
      this.props.settings &&
      this.props.settings.allowedStatuses &&
      Object.keys(this.props.settings.allowedStatuses).length > 0
    ) {
      if (
        job.status.toLowerCase() === JOB_STATUS.DONE &&
        !this.props.settings.allowedStatuses.hasOwnProperty(
          job.status.toUpperCase()
        )
      ) {
        return false;
      }

      if (
        job.status.toLowerCase() === JOB_STATUS.DONE &&
        this.props.settings.allowedStatuses[job.status.toUpperCase()] === true
      ) {
        return false;
      }

      if (!this.isUserInspector()) {
        return true;
      }

      return (
        this.isUserInspector() &&
        (typeof this.props.settings.allowedStatuses[
          job.status.toUpperCase()
        ] === "undefined" ||
          (typeof this.props.settings.allowedStatuses[
            job.status.toUpperCase()
          ] !== "undefined" &&
            this.props.settings.allowedStatuses[job.status.toUpperCase()] ===
              false))
      );
    } else if (this.props.settings && !this.props.settings.allowedStatuses) {
      return job.status.toLowerCase() !== JOB_STATUS.DONE;
    }

    return true;
  }

  async toggleAttendance() {
    const result = await toggleAttendance(
      this.state.currentJob._id,
      this.props.user.profile.id
    );

    if (result && result.success) {
      const confirmedUsers = this.state.currentJob.confirmedUsers || [];
      confirmedUsers.push({
        user: this.props.user.profile.id,
      });

      const jobs = this.state.jobs;

      if (jobs.length === 1) {
        jobs[0] = this.state.currentJob;
      }

      this.setState({
        currentJob: {
          ...this.state.currentJob,
          confirmedUsers: confirmedUsers,
        },
        jobs,
      });
    }
  }

  async removeAttendance() {
    const { removeAttendanceUserId } = this.state;

    if (!removeAttendanceUserId) {
      return false;
    }

    const result = await toggleAttendance(
      this.state.currentJob._id,
      removeAttendanceUserId
    );

    if (result && result.success === true) {
      const { currentJob } = this.state;

      if (currentJob.confirmedUsers) {
        const targetUser = currentJob.confirmedUsers.filter(
          (u) => u.user.toString() === removeAttendanceUserId.toString()
        );

        if (targetUser.length === 1) {
          currentJob.confirmedUsers.splice(
            currentJob.confirmedUsers.indexOf(removeAttendanceUserId),
            1
          );
          this.setState({
            currentJob,
            removeAttendanceConfirm: false,
          });
        }
      }
    }
  }

  getConfirmedUserName(userId) {
    const { currentJob } = this.state;

    if (!currentJob.users || currentJob.users.length === 0) {
      return "";
    }

    for (const user of currentJob.users) {
      if (user._id.toString() === userId.toString()) {
        if (user.name && user.name.first && user.name.last) {
          return `${user.name.first} ${user.name.last}`;
        }

        return user.email;
      }
    }

    return "";
  }

  handleTabChange(e, value) {
    this.setState({
      currentTab: value,
    });
  }

  onSkillsChange(selected) {
    this.setState({
      selectedSkills: selected.filter(
        (val) => val && val._id && typeof val._id !== "undefined"
      ),
    });
  }

  async assingSkills() {
    const { currentJob } = this.state;
    const updatedSelectedSkills = [...this.state.selectedSkills].filter(
      (skill) => !this.state.selectedToRemove.includes(skill)
    );
    const skillIds = updatedSelectedSkills.map((skill) => skill._id);
    const data = {
      skills: skillIds,
    };
    const response = await assignSkillsToJob(data, currentJob._id);
    if (response.success) {
      let updatedJob = await getCurrentJob({ id: currentJob._id }, "v2");
      this.setState({
        currentJob: updatedJob,
        selectedSkills: updatedSelectedSkills,
        initialSkills: updatedSelectedSkills,
        selectedToRemove: [],
        deleteSkillConfirm: false,
      });
    }
    this.closeModal();
  }

  onSelect() {
    if (this.state.selectedToRemove && this.state.selectedToRemove.length > 0) {
      this.closeModal();
      this.setState({
        deleteSkillConfirm: true,
      });
    } else {
      this.assingSkills();
    }
  }

  setSelectedToRemove = (selected) => {
    this.setState({ selectedToRemove: selected });
  };

  renderAddModal() {
    const AddSkillModal = withModal(SkillAutoComplete);
    return (
      <AddSkillModal
        FooterComponent={() => (
          <div className="actions Modal-actions">
            <button onClick={() => this.onSelect()} className="btn btn-primary">
              {i18n.t("default:_SELECT")}
            </button>
          </div>
        )}
        title="SELECT SKILLS"
        visible={this.state.addModalVisible}
        onClose={() => this.closeModal()}
        onSkillsChange={(selected) => this.onSkillsChange(selected)}
        selectedSuggestions={this.state.selectedSkills}
        selectedToRemove={this.state.selectedToRemove}
        initialSkills={this.state.initialSkills}
        setSelectedToRemove={this.setSelectedToRemove}
        skillColor={SKILL_COLORS.GREEN}
      />
    );
  }

  render() {
    const {
      jobs,
      selectionMode,
      deleteConfirm,
      deleteSkillConfirm,
      removeAttendanceConfirm,
      mode,
      user,
      changeStatus,
      assignUsersModal,
      showAnswerModal,
      selectedQuestions,
      shouldLoadSkillsTab,
      initialSkills,
      showCopyAlert,
      currentJob,
    } = this.state;

    const { classes } = this.props;
    if (jobs.length === 0) {
      return <div className="container" />;
    }
    if (mode === EDITOR_MODE.USER && user != null) {
      return (
        <UserEditor
          users={[user]}
          withBack={true}
          onBack={() => this.closeUser()}
        />
      );
    }

    return (
      <div className="JobEditor" onClick={(e) => this.handlePanelClick(e)}>
        {this.renderFileModal()}
        <ToastMessage
          showAlert={showCopyAlert}
          handleAlertClose={this.handleAlertClose}
          message={i18n.t("default:_LINK_COPIED")}
        />
        <FullScreenModal show={showAnswerModal}>
          <QuestionRenderer
            job={currentJob}
            jobType={currentJob.jobType}
            questions={selectedQuestions}
            done={(result) => this.questionAnswered(result)}
            update={(result) => this.updateAnswers(result)}
            close={() => this.setState({ showAnswerModal: false })}
          />
        </FullScreenModal>
        <UserSelectModal
          show={assignUsersModal}
          onChange={(users) => this.assignUsers(users)}
          close={() => this.updateAssignees()}
          includeMe={true}
        />
        {this.renderAddModal()}
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_CONFIRM_DELETE_JOBS", {
            jobs: i18n.t("default:_JOBS"),
          })}
          show={deleteConfirm}
          onConfirm={() => this.onDelete()}
          onClose={() => this.closeConfirm()}
        />
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_CONFIRM_REMOVE_ATTENDANCE")}
          show={removeAttendanceConfirm}
          onConfirm={() => this.removeAttendance()}
          onClose={() =>
            this.setState({
              removeAttendanceConfirm: false,
            })
          }
        />
        <Confirm
          title={i18n.t("default:_ARE_YOU_SURE")}
          message={i18n.t("default:_REMOVE_SKILLS")}
          show={deleteSkillConfirm}
          onConfirm={() => this.assingSkills()}
          onClose={() => {
            this.openModal();
            this.setState({
              deleteSkillConfirm: false,
            });
          }}
        />
        {this.renderEditProjectsModal()}
        {jobs.length > 0 && !selectionMode && (
          <div className="JobEditor EditorView">
            <JobHeader
              avatar={avatar}
              changeStatus={changeStatus}
              changeJobProp={this.changeJobProp.bind(this)}
              jobs={this.state.jobs}
              currentJob={currentJob}
              setCurrentJob={(changedJob) =>
                this.setState({
                  currentJob: changedJob,
                })
              }
              toggleChangeStatus={this.toggleChangeStatus.bind(this)}
              onShare={this.onShare.bind(this)}
              fromFiles={this.props.fromFiles}
              onUpdate={this.props.onUpdate}
              ref={this.statusSelectRef}
            />
            <div className="EditorView-content">
              <h4 className="hint-id">#{currentJob.ID}</h4>

              {this.props.apps.enabled.includes(APP_PROJECTS) && (
                <div className="col-md-12 m-b-30">
                  <h2 className="EditorView-title">
                    <p
                      onClick={() =>
                        this.props.history.push(
                          `/project/view/${
                            currentJob.project && currentJob.project._id
                          }`
                        )
                      }
                    >
                      {currentJob.project && currentJob.project.name}
                    </p>
                    {this.props.user &&
                      this.props.user.profile.role !== ROLE.INSPECTOR && (
                        <span
                          className="EditorView-hint-small"
                          onClick={() =>
                            this.setState({ modalProjectsOpen: true })
                          }
                        >
                          (
                          {i18n.t("default:_CHANGE_PROJECT", {
                            projectWord: i18n.t("default:_PROJECTS"),
                          })}
                          )
                        </span>
                      )}
                  </h2>
                </div>
              )}

              <div className="share-container">
                <textarea
                  ref={(ref) => (this.textAreaRef = ref)}
                  style={{
                    width: 0,
                    height: 0,
                    opacity: 0,
                  }}
                  value={this.state.copyLink}
                  readOnly
                />
              </div>

              <div className="m-b-30">
                <p className="editor-title">{i18n.t("default:_ATTENDANCE")}</p>
                <div className="row px-4 px-md-0">
                  <div className="col col-12 col-md-6">
                    {currentJob.users &&
                      currentJob.users.length > 0 &&
                      currentJob.confirmedUsers &&
                      currentJob.confirmedUsers.map((user) => (
                        <p
                          key={user.user}
                          data-tip={DateService.getFormattedDate(
                            user.created_at
                          )}
                          className={"editor-attendance in-blue"}
                        >
                          {this.getConfirmedUserName(user.user)}
                          {this.props.user.profile.role !== "inspector" && (
                            <i
                              className="mdi mdi-close-circle float-right JobEditor-remove_attendance"
                              onClick={() =>
                                this.setState({
                                  removeAttendanceUserId: user.user,
                                  removeAttendanceConfirm: true,
                                })
                              }
                            />
                          )}
                        </p>
                      ))}
                  </div>
                  <div className="col col-12 col-md-6">
                    {currentJob.users &&
                      currentJob.users.filter(
                        (u) =>
                          u &&
                          u._id &&
                          u._id.toString() ===
                            this.props.user.profile.id.toString()
                      ).length === 1 && (
                        <ConfirmationButton
                          onConfirm={() => this.toggleAttendance()}
                          isActive={
                            !!(
                              currentJob.confirmedUsers &&
                              currentJob.confirmedUsers.filter(
                                (u) =>
                                  u.user.toString() ===
                                  this.props.user.profile.id.toString()
                              ).length === 1
                            )
                          }
                          inactiveTooltip={i18n.t("default:_ONLY_CONFIRM")}
                          activeText={i18n.t("default:_CONFIRM")}
                          inactiveText={i18n.t("default:_CONFIRMED")}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="m-b-30">
                <p className="editor-title">{i18n.t("default:_JOB_TYPE")}</p>

                <div>
                  <h4 className="project-title">
                    {currentJob.jobType && currentJob.jobType.title}
                  </h4>
                </div>
              </div>

              {shouldLoadSkillsTab &&
                (this.props.user.profile.role !== "inspector" ||
                  !this.props.settings.security.hideSkillsFromInspector) && (
                  <div className="m-b-30" onClick={() => this.openModal()}>
                    <p className="editor-title">{i18n.t("default:_SKILLS")}</p>
                    {initialSkills.length > 0 ? (
                      <Grid>
                        {initialSkills.map((skill, index) => {
                          return (
                            <SkillChip
                              withMargin
                              backgroundColor={SKILL_COLORS.WHITE}
                              withAvatar
                              sidePanel={true}
                              skillAvatar={skill.avatar}
                              key={index}
                              name={skill.name}
                              skillColor={SKILL_COLORS.GREEN}
                            />
                          );
                        })}
                      </Grid>
                    ) : null}
                  </div>
                )}

              <div className="m-b-30 offsetInPanel">
                <p className="editor-title">
                  {i18n.t("default:_QUESTIONS_ANSWERS")}
                </p>
                {currentJob.status && this.canInspectorAnswer(currentJob) && (
                  <div className="answer-all-wrapper">
                    <button
                      onClick={() => this.editAll()}
                      className="btn btn-primary btn-full btn-answer-all"
                    >
                      {i18n.t("default:_ANSWER_ALL")}
                    </button>
                  </div>
                )}
                <AppBar className={classes.appBar} position="static">
                  <Tabs
                    value={this.state.currentTab}
                    onChange={this.handleTabChange.bind(this)}
                    aria-label="Job tabs"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab
                      className={classes.settingsTab}
                      style={{
                        ...(currentJob.questions &&
                        currentJob.jobType &&
                        currentJob.questions.length > 0 &&
                        currentJob.jobType.settings.previewSettings.filter(
                          (v) => v.value.toString() !== "-1"
                        ).length > 0
                          ? {}
                          : { display: "none" }),
                      }}
                      classes={{
                        selected: classes.selectedTab,
                        wrapper: classes.wrapper,
                      }}
                      label={
                        currentJob.jobType && currentJob.jobType.icon ? (
                          <div
                            data-tip={i18n.t("default:_MAIN_QUESTIONS")}
                            className="tab-icon"
                            style={{
                              backgroundImage: `url(${getFileTarget(
                                currentJob.jobType.icon,
                                "100x100"
                              )})`,
                            }}
                          />
                        ) : (
                          <div
                            className="tab-icon"
                            data-tip={i18n.t("default:_MAIN_QUESTIONS")}
                          >
                            <i className="mdi mdi-briefcase-outline" />
                          </div>
                        )
                      }
                    />
                    <Tab
                      className={classes.settingsTab}
                      style={{
                        ...(this.props.apps.enabled.includes(APP_PROJECTS)
                          ? {}
                          : { display: "none" }),
                      }}
                      classes={{
                        selected: classes.selectedTab,
                        wrapper: classes.wrapper,
                      }}
                      data-tip={i18n.t("default:_PROJECT")}
                      label={
                        <div
                          className="tab-icon"
                          style={{
                            backgroundImage: `url(${
                              currentJob.project && currentJob.project.avatar
                                ? getFileTarget(
                                    currentJob.project.avatar,
                                    "100x100"
                                  )
                                : avatar
                            })`,
                          }}
                        />
                      }
                    />
                    <Tab
                      className={classes.settingsTab}
                      classes={{
                        selected: classes.selectedTab,
                        wrapper: classes.wrapper,
                      }}
                      label={
                        <div
                          className="tab-icon"
                          data-tip={i18n.t("default:_ALL_QUESTIONS")}
                        >
                          <i className="icon-database" />
                        </div>
                      }
                    />
                  </Tabs>
                </AppBar>
                {currentJob.questions &&
                  currentJob.jobType &&
                  currentJob.questions.length > 0 &&
                  currentJob.jobType.settings.previewSettings.length > 0 && (
                    <TabPanel value={this.state.currentTab} index={0}>
                      {this.getPreviewQuestions().map((questionInfo, index) => {
                        const answers = currentJob.answers
                          ? currentJob.answers
                          : [];
                        const qs = currentJob.questions || [];
                        const currentAnswer = answers.filter(
                          (a) =>
                            a.question &&
                            a.question.ID.split("_")[0] === questionInfo.value
                        );
                        const currentQuestion = qs.filter(
                          (q) => q.ID === parseInt(questionInfo.value)
                        )[0];
                        return this.renderQuestion({
                          index,
                          question: currentQuestion,
                          currentAnswer:
                            currentAnswer[currentAnswer.length - 1],
                          project: currentJob.project,
                        });
                      })}
                    </TabPanel>
                  )}
                {this.props.apps.enabled.includes(APP_PROJECTS) && (
                  <TabPanel value={this.state.currentTab} index={1}>
                    <div className="ProjectFields">
                      <ReactTooltip effect="solid" />
                      <div>
                        <CircleUserList
                          //showAddButton={this.canAssignUsers() ? true : false}
                          job={currentJob ? currentJob : null}
                          ref={(ref) => {
                            this.refUsers = ref;
                          }}
                          users={
                            currentJob.project && currentJob.project.users
                              ? Array.isArray(currentJob.project.users)
                                ? currentJob.project.users.map((user) =>
                                    Object.assign(user, {
                                      avatar: user.imgUrl,
                                    })
                                  )
                                : [currentJob.project.users].map((user) =>
                                    Object.assign(user, {
                                      avatar: user && user.imgUrl,
                                    })
                                  )
                              : []
                          }
                        />
                      </div>
                      <ProjectFieldRenderer
                        vertical={true}
                        noPadding={true}
                        turnOffHidden={true}
                        projectData={currentJob.project}
                      />
                    </div>
                  </TabPanel>
                )}
                <TabPanel value={this.state.currentTab} index={2}>
                  {this.getAllQuestions().map((question, index) => {
                    if (question.type === QuestionType.STEP) {
                      return null;
                    }
                    const answers = currentJob.answers
                      ? currentJob.answers
                      : [];
                    const currentAnswer = answers.filter((a) => {
                      if (typeof a === "function") {
                        return null;
                      }
                      return (
                        a.question &&
                        a.question.ID.split("_")[0] === question.ID.toString()
                      );
                    });
                    return this.renderQuestion({
                      index,
                      question: question,
                      currentAnswer: currentAnswer[currentAnswer.length - 1],
                      project: currentJob.project,
                    });
                  })}
                </TabPanel>
              </div>
              {this.state.togglePreviousJobActive && (
                <div>
                  <ReactTooltip effect="solid" />
                  <PreviousJob
                    currentJob={currentJob}
                    setCurrentJob={(changedJob) =>
                      this.setState({
                        currentJob: changedJob,
                      })
                    }
                  />
                  <NextJob
                    currentJob={currentJob}
                    setCurrentJob={(changedJob) =>
                      this.setState({
                        currentJob: changedJob,
                      })
                    }
                  />
                </div>
              )}
              <JobActivities job={currentJob} settings={this.props.settings} />
            </div>
          </div>
        )}
        {(jobs.length > 1 || selectionMode) && (
          <div className="MultiUserView">
            <h2>
              {jobs.length +
                " " +
                i18n.t("default:_SELECTED_JOBS", {
                  jobs: i18n.t("default:_JOBS"),
                })}
            </h2>
            <div>
              {jobs.map((job, i) => (
                <MobileJobRow key={i} job={job} status={job.status} />
              ))}
            </div>
          </div>
        )}
        {jobs.length > 1 && !changeStatus && (
          <div onClick={() => this.toggleChangeStatus()}>
            <Button
              color="primary"
              fullWidth
              variant="outlined"
              style={{ width: "50%", height: "55px" }}
            >
              {i18n.t("default:_CHANGE_JOB_STATUS", {
                jobWord: i18n.t("default:_JOBS"),
              })}
            </Button>
            <ShowFilesButton customWidth={"50%"} jobInfo={jobs} />
          </div>
        )}
        {jobs.length === 1 && !changeStatus && !this.props.fromFiles && (
          <div>
            <ShowFilesButton jobInfo={jobs} />
          </div>
        )}
        {jobs.length > 1 && changeStatus && (
          <StatusSelect
            onStatusChange={(e) => this.changeJobStatusMulti(e.value)}
            currentJob={currentJob}
            changeStatus={changeStatus}
            ref={this.multiStatusSelectRef}
          />
        )}
        <div className="EditorView-actions">
          {jobs.length > 1 && (
            <button
              onClick={() => this.toggleAssignUsers()}
              className="btn-full btn-lightgray"
            >
              <i className="mdi mdi-account-plus-outline" />
              <span>
                {i18n.t("default:_ASSIGN_USERS", {
                  users: i18n.t("default:_USERS"),
                })}
              </span>
            </button>
          )}

          {jobs.length === 1 && this.canCloneJob() && (
            <button
              onClick={() => this.cloneJob()}
              className="btn-full btn-gray"
            >
              <i className="mdi mdi-content-duplicate" />
              <span>{i18n.t("default:_DUPLICATE")}</span>
            </button>
          )}
          {jobs.length === 1 && <ExportJobButton id={currentJob._id} />}
          {jobs.length > 1 && <ExportJobButton id={jobs} multiple={true} />}
          {this.canEraseJob() && (
            <button
              onClick={() => this.confirmDelete()}
              className="btn-full btn-red"
            >
              <i className="icon-cancel" />
              <span>{i18n.t("default:_ERASE")}</span>
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default connectWithStore(
  withRouter(withStyles(customStyles)(JobEditor)),
  [
    "settings",
    "user",
    "gridTable",
    "apps",
    "userAutocomplete",
    "blueprintEditor",
  ]
);
