import i18n from "../../../i18n";
import store from "../../../redux/store";
import { canSetBp } from "../../../services/access";
import { PROJECT_FIELD_TYPE } from "../../../pages/projects/fields/constants";
import Konva from "konva";
import { BlueprintEditor } from "../../../redux/actions";
import {
  MEASURE_TOOL_HORIZONTAL,
  MEASURE_TOOL_VERTICAL,
  MEASURE_TYPE,
  SCISSORS_TOOL,
  SHAPE,
} from "./constants";

export function getRelativePointerPosition(node) {
  var transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();

  // get pointer (say mouse or touch) position
  var pos = node.getStage().getPointerPosition();

  // now we can find relative point
  return transform.point(pos);
}

export function convertMeterToPixels(meter, type) {
  const state = store.getState();
  const { settings, blueprintEditor } = state;
  if (
    blueprintEditor.measurements &&
    blueprintEditor.measurements.pixels &&
    blueprintEditor.measurements.metrics
  ) {
    const { pixels, metrics } = blueprintEditor.measurements;
    if (type === "width") {
      const convertedWidth = meter * (pixels.width / metrics.width);
      if (isFinite(convertedWidth) && convertedWidth !== 0) {
        return convertedWidth.toFixed(2);
      }
    }
    if (type === "height") {
      const convertedHeight = meter * (pixels.height / metrics.height);
      if (isFinite(convertedHeight) && convertedHeight !== 0) {
        return convertedHeight.toFixed(2);
      }
    }
  }
  if (settings) {
    const { blueprintSettings } = settings;
    if (blueprintSettings) {
      const { ratio } = blueprintSettings;
      return meter * ratio;
    }
  }
  return meter * 360;
}

export function convertPixelsToUnit(px, type) {
  const state = store.getState();
  const { blueprintEditor } = state;
  if (blueprintEditor.measurements && blueprintEditor.measurements.pixels) {
    const { pixels, metrics } = blueprintEditor.measurements;
    if (type === "width") {
      const convertedWidth = px / (pixels.width / metrics.width);
      if (isFinite(convertedWidth) && convertedWidth !== 0) {
        return convertedWidth.toFixed(2);
      }
    }
    if (type === "height") {
      const convertedHeight = px / (pixels.height / metrics.height);
      if (isFinite(convertedHeight) && convertedHeight !== 0) {
        return convertedHeight.toFixed(2);
      }
    }
  }
  const convertedSize = px / 360;
  return convertedSize.toFixed(2);
}

export function getElementSize(json) {
  let width,
    height = 0;
  const parsed = JSON.parse(json);
  if (parsed.attrs.radius) {
    width = parsed.attrs.radius;
  }
  if (parsed.attrs.width) {
    width = parsed.attrs.width;
  }
  if (parsed.attrs.height) {
    height = parsed.attrs.height;
  }
  if (parsed.attrs.points) {
    const min = Math.min(...parsed.attrs.points);
    const max = Math.max(...parsed.attrs.points);
    width = max - min;
  }
  return { width, height };
}

export function getUnit() {
  const state = store.getState();
  const { settings } = state;
  if (settings) {
    const { blueprintSettings } = settings;
    if (blueprintSettings && blueprintSettings.systemUnit) {
      if (blueprintSettings.systemUnit === "Meters") {
        return i18n.t("default:_METERS_SIMPLIFIED");
      }
      if (blueprintSettings.systemUnit === "Inches") {
        return i18n.t("default:_INCHES_SIMPLIFED");
      }
    }
  }
  return i18n.t("default:_METERS_SIMPLIFIED");
}

export function addNewElementToJob(
  canvasData,
  elementName,
  tool,
  id,
  blueprintEditor
) {
  const jobElement = {
    canvasData: JSON.stringify({
      attrs: canvasData,
      className: elementName,
    }),
    created_at: +new Date(),
    visible: true,
    elementId: tool.element.id,
    _id: id,
  };
  const updatedJobs = [...blueprintEditor.jobs];
  if (updatedJobs && updatedJobs[0]) {
    updatedJobs[0].elements.push(jobElement);
  }
  return updatedJobs;
}

export function removeTransformers() {
  const state = store.getState();
  const { blueprintEditor } = state;

  if (
    blueprintEditor.layer.children &&
    blueprintEditor.layer.children.length > 0
  ) {
    for (let child of blueprintEditor.layer.children) {
      if (child._nodes && child._nodes.length > 0) {
        child.nodes([]);
      }
      blueprintEditor.layer.batchDraw();
    }
  }
}

export async function removeElements(elementName) {
  const state = store.getState();
  const { blueprintEditor } = state;

  if (
    blueprintEditor.layer.children &&
    blueprintEditor.layer.children.length > 0
  ) {
    const childrenArr = [...blueprintEditor.layer.children];

    for (let i = 0; i < childrenArr.length; i++) {
      if (childrenArr[i].attrs.name === elementName) {
        const id = childrenArr[i].attrs.id;
        if (id) {
          const shape = blueprintEditor.app.find("#" + id)[0];
          if (shape) {
            //await deleteElement(id);
            shape.destroy();
            blueprintEditor.app.batchDraw();
          }
        }
      }
    }
  }
}

export function getRgbaColor(color) {
  const r = color.rgb ? color.rgb.r : color.r;
  const g = color.rgb ? color.rgb.g : color.g;
  const b = color.rgb ? color.rgb.b : color.b;
  const a = color.rgb ? color.rgb.a : color.a;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function areMeasuresSet(measurements) {
  return (
    measurements &&
    measurements.arrows &&
    measurements.arrows.width &&
    measurements.arrows.height
  );
}

export function canUserEditElement(job, currentJob, question) {
  const state = store.getState();
  const { user } = state;
  const { profile } = user;
  const { role } = profile;
  if (!job || !currentJob || !question) {
    return false;
  }
  const jobId = job && job.ID;
  const currentJobId = currentJob && currentJob.ID;
  if (
    question &&
    question.settings.permissions &&
    question.settings.permissions[role]
  ) {
    if (jobId !== currentJobId) return false;
  }
  return true;
}

export function canSet(user, settings) {
  //check project field settings for role access
  const bpListField = settings.projectFields.filter(
    (field) => field.type === PROJECT_FIELD_TYPE.BLUEPRINT_LIST
  );
  const accessRules =
    bpListField.length > 0 ? bpListField[0].accessRules : null;
  return canSetBp(user, accessRules);
}

export function selectOrDeselectMeasure(transformer, dispatch, e, callType) {
  const state = store.getState();
  const { blueprintEditor } = state;
  const { isMeasuring, layer } = blueprintEditor;
  if (
    transformer &&
    transformer.current &&
    transformer.current._nodes &&
    transformer.current._nodes.length > 0
  ) {
    transformer.current.nodes([]);
    layer.batchDraw();
    dispatch(BlueprintEditor.setSelectedElement(null));
  }

  if (
    !isMeasuring &&
    e.target &&
    e.target.attrs &&
    e.target.attrs.mtype === callType
  ) {
    if (!transformer.current) {
      transformer.current = new Konva.Transformer({
        nodes: [e.target],
        //resizeEnabled: false
      });
      layer.add(transformer.current);
    } else {
      transformer.current.nodes([e.target]);
    }
    layer.batchDraw();
    dispatch(BlueprintEditor.setSelectedElement(e.target));
    //dispatch(BlueprintEditor.setShowHeightInput(true));
    return;
  }
}

export const getScaledHeight = (element) => {
  const height = element.height();
  const scale = element.scaleY();
  const scaledHeight = Math.abs(Math.floor(height * scale));
  return scaledHeight;
};

export const getScaledWidth = (element) => {
  const width = element.width();
  const scale = element.scaleX();
  const scaledWidth = Math.abs(Math.floor(width * scale));
  return scaledWidth;
};

export const drawMeasure = (measure, type, dispatch) => {
  const state = store.getState();
  const { blueprintEditor } = state;
  const currentMeasurements = { ...blueprintEditor.measurements };
  const nodeData = measure.canvasData.attrs;
  const arrow = nodeData
    ? new Konva.Line(nodeData)
    : Konva.Node.create({
      ...JSON.parse(measure.canvasData),
    });

  arrow.on("transformend", () => {
    if (type === MEASURE_TYPE.HEIGHT) {
      const scaledHeight = getScaledHeight(arrow);
      currentMeasurements.pixels.height = scaledHeight;
      dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
    }
    if (type === MEASURE_TYPE.WIDTH) {
      const scaledWidth = getScaledWidth(arrow);
      currentMeasurements.pixels.width = scaledWidth;
      dispatch(BlueprintEditor.setMeasurements(currentMeasurements));
    }
  });
  blueprintEditor.layer.add(arrow);
  blueprintEditor.layer.batchDraw();
};

export const isPolygon = (type) => {
  return (
    type === SHAPE.POLYGON_OUTLINED ||
    type === SHAPE.POLYGON_FILLED ||
    type === SHAPE.POLYGON_OPEN
  );
};

export const getLinearLength = (points) => {
  const sides = points.length - 3;
  let length = 0;
  for (let i = 0; i < sides; i += 2) {
    const x1 = Number(points[i]);
    const x2 = Number(points[i + 2]);
    const y1 = Number(points[i + 1]);
    const y2 = Number(points[i + 3]);

    const aInPx = Math.abs(x1 - x2);
    const a = Number(convertPixelsToUnit(aInPx, "width"));
    const bInPx = Math.abs(y1 - y2);
    const b = Number(convertPixelsToUnit(bInPx, "height"));

    const c = Math.sqrt(a * a + b * b);
    length += c;
  }
  const convertedLength = length.toFixed(2);
  return Number(convertedLength);
};

export const getPolygonPoints = (id) => {
  const state = store.getState();
  const { blueprintEditor } = state;
  const target = blueprintEditor.app.find("#" + id);
  const points = target && target[0] ? target[0].attrs.points : [];
  return points;
};

export const getAreaFromCoords = (points) => {
  let a = 0;
  // Must have even number of elements
  if (points.length % 2) return;

  // Process pairs, increment by 2 and stop at length - 2
  for (let i = 0, iLen = points.length - 2; i < iLen; i += 2) {
    const x1InPx = Number(points[i]);
    const x1 = Number(convertPixelsToUnit(x1InPx, "width"));
    const x2InPx = Number(points[i + 2]);
    const x2 = Number(convertPixelsToUnit(x2InPx, "width"));
    const y1InPx = Number(points[i + 1]);
    const y1 = Number(convertPixelsToUnit(y1InPx, "height"));
    const y2InPx = Number(points[i + 3]);
    const y2 = Number(convertPixelsToUnit(y2InPx, "height"));
    a += x1 * y2 - x2 * y1;
  }
  const area = Math.abs(a / 2);
  return area.toFixed(2);
};

export const setScaledPoints = (node) => {
  const state = store.getState();
  const { blueprintEditor } = state;
  const { app } = blueprintEditor;

  const appX = app.x();
  const appY = app.y();

  const oldPoints = node.points();
  const newPoints = [];
  for (var i = 0; i < oldPoints.length / 2; i++) {
    const point = node.getAbsoluteTransform().point({
      x: oldPoints[i * 2],
      y: oldPoints[i * 2 + 1],
    });
    newPoints.push(point.x, point.y);
  }
  node.points(newPoints);
  node.scaleX(1);
  node.scaleY(1);
  node.x(appX ? Number(-appX) : 0);
  node.y(appY ? Number(-appY) : 0);
};

export const getUnitSize = (shape, width) => {
  const units = getUnit();
  //set scale if type is line or image
  const scaleX =
    shape && shape.attrs && shape.attrs.scaleX ? shape.attrs.scaleX : 1;
  const scaleY =
    shape && shape.attrs && shape.attrs.scaleY ? shape.attrs.scaleY : 1;
  const shapeHeight = shape && shape.height();
  let shapeWidth = shape && shape.width();

  return (
    convertPixelsToUnit(shapeWidth ? shapeWidth * scaleX : width, "width")
      .toString()
      .concat(
        shapeHeight > 0
          ? "x" + convertPixelsToUnit(shapeHeight * scaleY, "height")
          : ""
      ) + units
  );
};

export const updateLineWidth = (shape, convertedValue) => {
  const attrs = shape.attrs;
  //adjust width according to the scale
  const scale = attrs.scaleX ? attrs.scaleX : 1;
  const updatedAttrs = { ...attrs };
  const startPoint = updatedAttrs.points[0];
  const endPoint = startPoint + convertedValue / scale;
  updatedAttrs.points[2] = endPoint;
  updatedAttrs.points[4] = endPoint;
  shape.setAttrs({
    ...updatedAttrs,
  });
};

export const getLineWidth = (shape) => {
  const startPoint = shape.attrs.points[0];
  const endPoint = shape.attrs.points[2];
  const scale = shape.attrs.scaleX ? shape.attrs.scaleX : 1;
  const adjustedWidth = (endPoint - startPoint) * scale;
  return adjustedWidth;
};

export const canProceedEditingElement = (mode, tool) => {
  return (
    mode === "EDIT" &&
    tool.type !== MEASURE_TOOL_HORIZONTAL &&
    tool.type !== MEASURE_TOOL_VERTICAL &&
    tool.type !== SCISSORS_TOOL
  );
};

export const findShapeById = (shapeId) => {
  const state = store.getState();
  const { blueprintEditor } = state;
  return blueprintEditor.app.find("#" + shapeId)[0];
};

export const subscribeScissorsToParentPosition = (parent) => {
  const scissorsId = parent.attrs.scissorsId;
  const scissorsShape = findShapeById(scissorsId);
  if (scissorsShape) {
    scissorsShape.setAttr("lastParentX", parent.x());
    scissorsShape.setAttr("lastParentY", parent.y());
  }
};

export const updateScissorsPositionOnParentDrag = (parent) => {
  const scissorsId = parent.attrs.scissorsId;
  const scissorsShape = findShapeById(scissorsId);
  if (scissorsShape) {
    const lastParentX = scissorsShape.attrs && scissorsShape.attrs.lastParentX;
    const lastParentY = scissorsShape.attrs && scissorsShape.attrs.lastParentY;
    const xDistance =
      lastParentX && scissorsShape.x() ? scissorsShape.x() - lastParentX : 0;
    const yDistance =
      lastParentY && scissorsShape.y() ? scissorsShape.y() - lastParentY : 0;
    scissorsShape.x(parent.x() + xDistance);
    scissorsShape.y(parent.y() + yDistance);
  }
};

export const removeShape = (shape) => {
  const state = store.getState();
  const { blueprintEditor } = state;
  const groupId = shape.attrs.groupId;
  if (groupId) {
    const groupShape = blueprintEditor.app.find("#" + groupId);
    groupShape.destroy();
  }
  shape.destroy();
  blueprintEditor.app.batchDraw();
};

export const getA4Crop = ({ height, width }) => {
  let a4Height = height;
  let a4Width = width;
  let ratio = 1;

  // max height and width depend on the header and footer sizes of the pdf document
  if (height > 580) {
    ratio = height / 580;
    a4Height = 580;
    a4Width = width / ratio;
  }

  if (a4Width > 500) {
    ratio = a4Width / 500;
    a4Width = 500;
    a4Height = a4Height / ratio;
  }

  const a4Sizes = {
    height: a4Height,
    width: a4Width,
  };
  return a4Sizes;
};
