import React, { /* useCallback,  */ useEffect, useState } from "react";
import { DateType, QuestionType } from "../../services/question";
import DateService from "../../services/date";
import moment from "moment";
import i18n from "../../i18n";
import { getFileTarget } from "../../services/api/file_upload";
import RichTextRenderer from "../../components/question/rich-text-renderer";
import { useTheme } from "@material-ui/core/styles";
import { connectWithStore } from "../../services/redux";
import {
  convertPixelsToUnit,
  getElementSize,
  getUnit,
} from "../blueprints/editor/utils";
import { Grid, Button } from "@material-ui/core";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
//import { getQuestionElements } from "../../services/api/blueprints";
import ElementContainer from "../blueprints/editor/elements/element_container";
import TiledListItem from "../question/types/tiled_list_item";
import { PROJECT_FIELD_TYPE } from "../../pages/projects/fields/constants";


// Regular expression for phone numbers
const phoneRegex = /(?:[\u0590-\u05FF\s]*)05\d{1,2}-?\d{6,7}(?:[\u0590-\u05FF\s]*)/g;

const renderWithPhoneNumbers = (text) => {
  if (!text) return text;

  // Split the text based on phone numbers
  const parts = text.split(phoneRegex);
  const matches = text.match(phoneRegex);

  // If no matches, return the original text
  if (!matches) {
    return <span>{text}</span>;
  }

  // Rebuild the text with "Call" buttons next to the phone numbers
  return parts.map((part, index) => (
    <React.Fragment key={index}>
      {part}
      {matches[index] && (
        <Button className="callButton" variant="link" onClick={() => window.location.href = `tel:${matches[index].replace(/\D/g, '')}`}>
          {matches[index]}
        </Button>
      )}
    </React.Fragment>
  ));
};

const PhoneNumberWithCallButton = ({ value }) => {
  // Function to process the value and replace phone numbers with JSX


  return <p>
    {renderWithPhoneNumbers(value)}
  </p>
}

const avatar = require("../../assets/img/project-placeholder.png");

const BlueprintValue = connectWithStore(
  function BlueprintValueComponent({ settings, elements, blueprintEditor }) {
    const [bpElements, setBpElements] = useState([]);
    const [uniqueBpElements, setUniqueBpElements] = useState([]);
    useEffect(() => {
      const nextElements = [];
      const units = getUnit();
      elements.forEach((el) => {
        const tools = settings.blueprintSettings.elements
          ? settings.blueprintSettings.elements.filter(
            (t) => t.id === el.elementId
          )
          : [];
        if (tools.length > 0) {
          const { width, height } = getElementSize(el.canvasData);
          nextElements.push({
            color: tools[0].color,
            name: tools[0].name,
            type: tools[0].type,
            width:
              convertPixelsToUnit(width)
                .toString()
                .concat(height > 0 ? "x" + convertPixelsToUnit(height) : "") +
              units,
          });
        } else {
          const parsedData = JSON.parse(el.canvasData);
          if (
            !(
              parsedData &&
              parsedData.className &&
              parsedData.className === "Arrow"
            )
          ) {
            nextElements.push({
              color: { hex: "#000" },
              name: i18n.t("default:_REMOVED_ELEMENT"),
              type: -1,
              width: 0 + units,
            });
          }
        }
      });
      const uniqueElements = [];
      const map = new Map();
      for (const item of nextElements) {
        if (!map.has(item.name)) {
          map.set(item.name, true); // set any value to Map
          uniqueElements.push({
            color: item.color,
            name: item.name,
            type: item.type,
            width: item.width,
          });
        }
      }
      setBpElements(nextElements);
      setUniqueBpElements(uniqueElements);
    }, [settings, elements]);
    return (
      <div>
        {uniqueBpElements.map((bp, i) => {
          const bpQuantity = bpElements.filter((el) => el.name === bp.name)
            .length;
          return (
            <Grid container key={i}>
              <ElementContainer
                type={bp.type}
                buttonColor={bp.color}
                label={bp.name}
                width={bp.width}
                quantity={bpQuantity}
              />
            </Grid>
          );
        })}
      </div>
    );
  },
  ["settings", "blueprintEditor"]
);

function RenderField({ project, field }) {
  if (!project || !field || !project[field.id]) {
    return <p></p>;
  }
  const fieldOfProject = project[field.id];

  let fieldValue =
    fieldOfProject && fieldOfProject.address
      ? fieldOfProject.address
      : fieldOfProject;

  if (!fieldValue) {
    return <p></p>;
  }

  if (field.type === PROJECT_FIELD_TYPE.TABLE) {
    fieldValue = fieldValue.map((f) => {
      return (
        <p>
          {Object.keys(f)
            .map((fk) => `${fk}: ${f[fk]}`)
            .join(",")}
        </p>
      );
    });
  } else if (field.type === PROJECT_FIELD_TYPE.LIST) {
    fieldValue = fieldValue.map((f) => f.label).join(",");
  }
  return (
    <div>
      <p dir="auto" className="dialog-tooltip-target">
        {fieldValue}
      </p>
    </div>
  );
}

function AnswerValue({ currentAnswer, question, project, settings }) {
  const theme = useTheme();
  /* const [elements, setElements] = useState([]);

  const getElements = useCallback(async () => {
    const rows = await getQuestionElements({
      questionId: question._id,
    });

    const currentElements = [];
    rows.forEach((row) => {
      const { elements } = row;
      elements.forEach(({ canvasData, elementId, user, _id, visible }) => {
        currentElements.push({
          canvasData,
          id: _id,
          elementId,
          user,
          visible,
        });
      });
    });
    setElements(currentElements);
  }, [question]);

  useEffect(() => {
    if (
      currentAnswer &&
      currentAnswer.question.type === QuestionType.BLUEPRINT
    ) {
      getElements();
    }
  }, [getElements, currentAnswer]); */

  const getPunchClockEndTime = (startTime, endTime) => {
    if (moment(startTime).isSame(endTime, "day")) {
      return ` - ${DateService.getFormattedTime(endTime)}`;
    } else {
      return ` - ${DateService.getFormattedDate(endTime)}`;
    }
  };

  const getRangeValue = (answer, value) => {
    switch (answer.question.settings.type) {
      case DateType.TIME:
        return `${moment(value.from).format("HH:mm")} - ${moment(
          value.to
        ).format("HH:mm")}`;
      case DateType.DATE:
        return `${moment(value.from).format("DD/MM/YY")} - ${moment(
          value.to
        ).format("DD/MM/YY")}`;
      case DateType.DATE_TIME:
        return `${moment(value.date ? value.date : value.from).format(
          "DD/MM/YY"
        )}, ${moment(value.from).format("HH:mm")} - 
        ${moment(value.date ? value.date : value.to).format(
          "DD/MM/YY"
        )}, ${moment(value.to).format("HH:mm")}`;
      default:
        return `${moment(value.from).format("DD/MM/YY, HH:mm")} - ${moment(
          value.to
        ).format("DD/MM/YY, HH:mm")}`;
    }
  };

  const getAnswerValue = (answer) => {
    const value = answer ? answer.value : null;
    if (!answer) {
      return value;
    }
    let preformatedValue;
    switch (answer.question.type) {
      case QuestionType.BLUEPRINT:
        preformatedValue = (
          <BlueprintValue
            elements={
              answer.elements && answer.elements.length > 0
                ? answer.elements
                : []
            }
          />
        );
        break;
      case QuestionType.DATE_TIME:
        if (typeof value == "object" && value !== null) {
          if (
            answer.question.settings.isRange &&
            !answer.question.settings.isPunchClock
          ) {
            preformatedValue = (
              <div>
                <div className="dialog-tooltip">
                  <p dir="auto" className="dialog-tooltip-target">
                    {value.from && value.to && getRangeValue(answer, value)}
                  </p>
                </div>
              </div>
            );
          } else if (
            answer.question.settings.isRange &&
            answer.question.settings.isPunchClock
          ) {
            preformatedValue = (
              <div>
                <div className="dialog-tooltip">
                  <p dir="auto" className="dialog-tooltip-target">
                    {value.punchStart &&
                      DateService.getFormattedDate(value.punchStart)}
                    {value.punchEnd &&
                      getPunchClockEndTime(value.punchStart, value.punchEnd)}
                    {value.punchClockOn && (
                      <AccessAlarmIcon color="primary" className="blink" />
                    )}
                  </p>
                </div>
              </div>
            );
          } else {
            preformatedValue = (
              <div>
                <div className="dialog-tooltip">
                  <p dir="auto" className="dialog-tooltip-target">
                    {value &&
                      value.value &&
                      DateService.getFormattedDate(value.date)}
                  </p>
                </div>
              </div>
            );
          }
        } else {
          preformatedValue = (
            <div>
              <div className="dialog-tooltip">
                <p dir="auto" className="dialog-tooltip-target">
                  {value}
                </p>
              </div>
            </div>
          );
        }
        break;
      case QuestionType.LIST:
        if (value) {
          let toFormat = value;
          let showMore = false;
          if (!Array.isArray(value)) {
            toFormat = [value];
          }
          /* toFormat = toFormat.filter((v) => {
            if (
              currentAnswer.question.settings.childs &&
              typeof v === "string"
            ) {
              return currentAnswer.question.settings.childs
                .map((child) => (Array.isArray(child) ? child[0] : child))
                .includes(v);
            }
            return true;
          }); */
          if (toFormat.length > 3) {
            toFormat = toFormat.slice(0, 3);
            showMore = true;
          }
          preformatedValue = (
            <div className="ListComponent">
              <ul className="list-answers">
                {toFormat.map((item, index) => {
                  const isTileQuestion =
                    answer.question.settings.tiledList || false;
                  return (
                    <li
                      className={"ListComponent-Item SidePanelListItem"}
                      key={index}
                      style={{
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {typeof item === "object" && "avatar" in item && (
                        <div className="ListComponent-Avatar">
                          <div className="ListComponent-AvatarCircle">
                            {item.avatar && (
                              <img src={item.avatar} alt={item.value} />
                            )}
                          </div>
                        </div>
                      )}
                      {isTileQuestion ? (
                        <TiledListItem item={item} />
                      ) : (
                        <div className="dialog-tooltip">
                          <p dir="auto" className="dialog-tooltip-target">
                            {item.value || item}
                          </p>
                        </div>
                      )}
                    </li>
                  );
                })}
              </ul>
              {showMore && (
                <div className="show-more">{i18n.t("default:_SEE_MORE")}</div>
              )}
            </div>
          );
        }
        break;
      case QuestionType.SYSTEM_LIST:
      case QuestionType.PROJECT_LIST:
        if (value) {
          let toFormat = value;
          let showMore = false;
          if (!Array.isArray(value)) {
            toFormat = [value];
          }
          if (toFormat.length > 3) {
            toFormat = toFormat.slice(0, 3);
            showMore = true;
          }
          preformatedValue = (
            <div className="ListComponent">
              <ul className="list-answers">
                {toFormat.map((item, index) => {
                  return (
                    <li
                      className={"ListComponent-Item SidePanelListItem"}
                      key={index}
                      style={{
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    >
                      {typeof item === "object" && "avatar" in item && (
                        <div className="ListComponent-Avatar">
                          <div className="ListComponent-AvatarCircle">
                            {item.avatar && (
                              <img src={item.avatar} alt={item.value} />
                            )}
                            {!item.avatar && (
                              <img src={avatar} alt={item.value} />
                            )}
                          </div>
                        </div>
                      )}
                      <div>
                        <div className="dialog-tooltip">
                          <p dir="auto" className="dialog-tooltip-target">
                            {renderWithPhoneNumbers(item.value || item)}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {showMore && (
                <div className="show-more">{i18n.t("default:_SEE_MORE")}</div>
              )}
            </div>
          );
        }
        break;
      case QuestionType.BOOL:
        if (value) {
          preformatedValue = value.toString();
          if (preformatedValue === "YES") {
            preformatedValue = i18n.t("default:_YES");
          }
          if (preformatedValue === "NO") {
            preformatedValue = i18n.t("default:_NO");
          }
          preformatedValue = (
            <div>
              <div className="dialog-tooltip">
                <p dir="auto" className="dialog-tooltip-target">
                  {preformatedValue}
                </p>
              </div>
            </div>
          );
        }
        break;
      case QuestionType.IMAGE:
        if (
          !(
            answer &&
            answer.question &&
            answer.question.settings &&
            answer.question.settings.childs
          )
        ) {
          return;
        }
        const filter = answer.question.settings.childs.filter(
          (img) => img.value === value
        );

        preformatedValue = (
          <div>
            <div className="dialog-tooltip">
              <p className="dialog-tooltip-target">
                <img
                  src={getFileTarget(
                    filter && filter[0] && filter[0].file && filter[0].file,
                    "100x100"
                  )}
                  alt={value}
                />
              </p>
            </div>
          </div>
        );
        break;
      case QuestionType.TEXT:
        typeof value !== "string"
          ? (preformatedValue = (
            <div>
              <RichTextRenderer value={value} />
            </div>
          ))
          : (preformatedValue = (<PhoneNumberWithCallButton value={value} />));
        break;
      case QuestionType.PROJECT_TABLE:
        let showMore = false;
        let entries = [];
        if (Array.isArray(value)) {
          entries = value && value[0] ? Object.entries(value[0]) : [];
        } else {
          entries = value ? Object.entries(value) : [];
        }
        const columnFilter =
          answer &&
            answer.question &&
            answer.question.settings &&
            answer.question.settings.filter
            ? answer.question.settings.filter
            : [];
        const columnFilterLabels = columnFilter.map((filter) => filter.label);
        const filteredEntries = entries.filter((entry) =>
          columnFilterLabels.includes(entry[0])
        );
        const values = filteredEntries.map((value) => value[1]);
        let renderedValues = [...values];
        if (values.length > 3) {
          renderedValues = values.slice(0, 3);
          showMore = true;
        }
        preformatedValue = (
          <div className="ListComponent">
            <ul className="list-answers">
              {renderedValues.map((item, index) => {
                return (
                  <li
                    className={"ListComponent-Item SidePanelListItem"}
                    key={index}
                    style={{
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <div className="dialog-tooltip">
                      <p dir="auto" className="dialog-tooltip-target">
                        {item}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
            {showMore && (
              <div className="show-more">{i18n.t("default:_SEE_MORE")}</div>
            )}
          </div>
        );
        break;
      case QuestionType.PROJECT_VALUE:
        const targetField = question.settings.field;
        const { projectFields } = settings;

        if (targetField === "all") {
          preformatedValue = projectFields.map((pf) =>
            RenderField({ project, field: pf })
          );
          break;
        }
        const pff = projectFields.filter(
          (field) => field.label === targetField
        );
        preformatedValue = RenderField({ project, field: pff[0] });
        break;
      default:
        if (typeof value !== "object") {
          preformatedValue = (
            <div>
              <p dir="auto" className="dialog-tooltip-target">
                {value}
              </p>
            </div>
          );
        }
        break;
    }
    return preformatedValue;
  };

  return <div>{currentAnswer && getAnswerValue(currentAnswer)}</div>;
}

export default AnswerValue;
